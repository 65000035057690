<template>
    <PageWrapper>
        <SwitchAccountGrid
            label="Select Account"
            @select="$emit('select', $event)"
            @error="$log('Failed to select an account...', $event)"
        />
    </PageWrapper>
</template>

<script>
    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- COMPONENTS -->
    import PageWrapper from '@/components/PageWrapper.vue';

    // <!-- FIXTURES -->
    import SwitchAccountGrid from '@/features/switch-account/components/SwitchAccountGrid.vue';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'SelectAccount',
        components: {
            PageWrapper,
            SwitchAccountGrid,
        },
        emits: ['select', 'error'],
    });
</script>
