<template>
    <div class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <div class="flex justify-center">
                <h2
                    class="mt-6 text-center text-3xl font-extrabold text-gray-900"
                >
                    Login to eClimateNotebook
                </h2>
                <span class="mt-6 ml-0.5">®</span>
            </div>
            <!-- <div class="mt-6 text-center">
                <a
                    href="http://webstaging.eclimatenotebook.com/pricing/"
                    class="font-bold text-primary-600 hover:text-primary-400"
                    >No Account? Learn More</a
                >
            </div> -->
        </div>

        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <LoadingWrapper :isLoading="isLoading">
                    <FormKit
                        type="form"
                        id="login-form"
                        :actions="false"
                        v-model="formData"
                        #default="context"
                        :config="config"
                        :errors="formErrors"
                        aria-autocomplete="on"
                        autocomplete="on"
                    >
                        <!-- Username -->
                        <FormKit
                            type="text"
                            id="username"
                            name="username"
                            label="Username"
                            aria-autocomplete="username"
                            autocomplete="username"
                            outer-class="outer mt-1"
                            label-class="$reset label block text-sm font-bold text-gray-700"
                            input-class="$reset text-input appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                            validation="required"
                            validation-label="Username"
                            @keydown.enter.prevent.stop="focusPasswordInput"
                        />

                        <!-- Password -->
                        <FormKit
                            type="password"
                            id="password"
                            name="password"
                            label="Password"
                            aria-autocomplete="current-password"
                            autocomplete="current-password"
                            outer-class="outer mt-1"
                            label-class="$reset label block text-sm font-bold text-gray-700"
                            input-class="$reset text-input appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                            validation="required"
                            validation-label="Password"
                            @keydown.enter.prevent.stop="clickLoginButton"
                        />

                        <div class="flex items-center justify-between mb-5">
                            <div class="flex items-center">
                                <FormKit
                                    type="checkbox"
                                    label="Remember Me"
                                    name="remember"
                                    outer-class="$reset outer p-0 hover:cursor-pointer"
                                    wrapper-class="$reset wrapper flex flex-row items-center h-8 space-x-1"
                                    inner-class="$reset inner block h-8 my-1"
                                    input-class="$reset checkbox block h-4 w-4 my-2 text-primary-600 focus:ring-primary-500 border-gray-300 rounded hover:cursor-pointer"
                                    label-class="$reset label block pl-1 text-gray-600 text-sm hover:text-primary-400 hover:cursor-pointer"
                                    validation="?"
                                    validation-label="Remember Me"
                                    @keyup.enter.stop="
                                        /** @type {HTMLElement} */ (
                                            $event?.target
                                        )?.click()
                                    "
                                />
                            </div>
                            <div class="flex items-center">
                                <router-link
                                    to="/password/forgotten"
                                    class="text-primary-600 text-sm hover:text-primary-400"
                                    exact
                                >
                                    Forgot your password?
                                </router-link>
                            </div>
                        </div>

                        <div class="flex justify-center">
                            <div class="min-w-full mx-auto">
                                <VariantButton
                                    id="login"
                                    name="login"
                                    variant="login"
                                    label="Login"
                                    @click="onLoginSubmit"
                                    :disabled="
                                        !context.state.valid && !isLoading
                                    "
                                />
                            </div>
                        </div>
                    </FormKit>
                    <!-- <div
                        v-if="debug"
                        class="whitespace-pre-line p-2 rounded-lg bg-gray-50"
                    >
                        {{
                            {
                                formData: formData,
                            }
                        }}
                    </div> -->
                </LoadingWrapper>
            </div>
        </div>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, ref, reactive, computed } from 'vue';
    import { useRouter } from 'vue-router';
    import { useStore } from 'vuex';
    import { requestOAuthToken } from '@/api/auth';

    // <!-- UTILITIES -->
    import { promiseTimeout } from '@vueuse/shared';
    import {
        withEventTarget,
        clickSiblingFormElement,
        focusSiblingFormElement,
    } from '@/utils/html';

    // <!-- COMPONENTS -->
    import LoadingWrapper from '@/components/LoadingWrapper.vue';
    import VariantButton from '@/components/buttons/VariantButton.vue';

    // <!-- UTILS -->
    import { useCookie } from '@/hooks/useCookie';

    // <!-- TYPES -->

    /** @template [S=any] @typedef {import('vuex').Store<S>} Store<S> */
    /** @typedef {Router.Router} Router */
    /** @typedef {import('@formkit/core').FormKitProps} FormKitProps */
    /** @typedef {import('@formkit/core').FormKitConfig} FormKitConfig */
    /** @typedef {import('axios').AxiosError} AxiosError */

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'Login',
        components: {
            VariantButton,
            LoadingWrapper,
        },
        props: {
            /** FormKit configuration */
            config: {
                /** @type {V.PropType<Partial<FormKitConfig & FormKitProps>>} */
                type: Object,
                /** @type {() => Partial<FormKitConfig & FormKitProps>} */
                default: () => ({
                    delay: 50,
                    validationVisibility: 'blur',
                }),
            },
            /** Debug mode. */
            debug: {
                type: Boolean,
                default: false, // HACK: Set to true to get debug mode content.
            },
        },
        setup(props, context) {
            /** @type {Store} */
            const store = useStore();

            const router = useRouter();

            // Username cookie.
            const cookie = {
                username: useCookie('login_username'),
            };

            /** @type {V.ComputedRef<String>} */
            const storedUsername = computed(() => {
                const result = cookie.username.read();
                return !result ? '' : result[1];
            });

            /**
             * Invoke the focus event on the password input element.
             * @param {{ target: HTMLInputElement }} e
             */
            const focusPasswordInput = (e) => {
                withEventTarget(e).then((target) =>
                    focusSiblingFormElement(target, 'password')
                );
            };

            /**
             * Invoke the click event on the login button element.
             * @param {{ target: HTMLInputElement }} e
             */
            const clickLoginButton = (e) => {
                withEventTarget(e).then((target) =>
                    clickSiblingFormElement(target, 'login')
                );
            };

            /** Form data backing the form. */
            const initializeFormData = () => ({
                username: storedUsername.value,
                password: '',
                remember: false,
                /** @type {string[]} */
            });

            /** @typedef {ReturnType<initializeFormData>} FormData */

            /** @type {V.Ref<FormData>} */
            const formData = ref(initializeFormData());
            const formErrors = reactive([]);

            /** @type {V.ComputedRef<Boolean>} */
            const isPasswordValid = computed(
                () => formData.value.password.length > 0
            );

            /** @type {V.ComputedRef<Boolean>} */
            const isRemembering = computed(
                () => formData.value.remember === true
            );

            const isLoading = ref(false);

            const setErrors = (errors) => (formData.value.errors = errors);
            const clearErrors = () => (formData.value.errors = []);
            const setError = (error) => {
                const exists = formData.value.errors.find((e) => e === error);
                if (!exists) {
                    setErrors([...formData.value.errors, error]);
                }
            };

            /** @param {Event} event */
            const onLoginSubmit = async (event) => {
                event.preventDefault();

                while (formErrors.length > 0) {
                    formErrors.pop();
                }

                try {
                    if (isRemembering.value) {
                        console.log(`Remembering current username.`);
                        // Save for 30 days.
                        cookie.username.write(
                            formData.value.username,
                            30 * 24 * 60 * 60 * 1000
                        );
                    } else {
                        // Clear the cookie if it is not set to remember.
                        cookie.username.delete();
                    }

                    // Save password credentials if remembering.
                    const form = document.getElementById('login-form');
                    if (
                        isRemembering.value &&
                        // TODO: Enhance with global type
                        // @ts-ignore
                        !!window.PasswordCredential &&
                        !!form
                    ) {
                        // READ: https://web.dev/security-credential-management-save-forms/#update-the-ui
                        console.log(`Saving password credentials`);
                        // @ts-ignore // TODO: Enhance global types.
                        const credentials = new PasswordCredential(form);
                        // Trigger the store password promise.
                        navigator.credentials.store(credentials);
                    }
                } catch (error) {
                    console.warn(`Failed to remember the username.`);
                    console.error(error);
                }

                try {
                    if (!isPasswordValid.value) {
                        throw new Error('Missing password!');
                    }
                    isLoading.value = true;
                    // Wait the delay, to ensure the form has updated.
                    // await promiseTimeout(50);
                    const response = await requestOAuthToken({
                        username: formData.value.username,
                        password: formData.value.password,
                    });
                    setAccessToken(response.access_token);
                    setRefreshToken(response.refresh_token);
                    // Dispatch a fetch request for the current user.
                    await store.dispatch('fetchCurrentUser');
                    // Clear session storage of selected account, if present.
                    sessionStorage.removeItem('selected_account');
                    // Redirect to the Select Account page.
                    router.push('/select-account');
                } catch (error) {
                    isLoading.value = false;
                    const e = /** @type {AxiosError} */ (error);
                    console.dir(e);
                    if (!!e?.isAxiosError) {
                        const message =
                            e.response.data?.error_description ??
                            'An unknown server error occurred.';
                        formErrors.push(message);
                    }
                } finally {
                    isLoading.value = false;
                }
            };

            /** @param {String} token */
            const setAccessToken = (token) => {
                localStorage.setItem('auth_token', token);
            };

            /** @param {String} token */
            const setRefreshToken = (token) => {
                localStorage.setItem('refresh_token', token);
            };

            // <!-- EXPOSE -->
            return {
                isLoading,
                clickLoginButton,
                focusPasswordInput,
                onLoginSubmit,
                setError,
                setErrors,
                clearErrors,
                setAccessToken,
                setRefreshToken,
                formData,
                formErrors,
            };
        },
    });
</script>
